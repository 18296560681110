require("./sass/style.scss");

jQuery(document).ready(function($) {
	jQuery.lazify_this = function(sizes) {
		var vWidth = $(window).width(),
			imageKey = "hero-banner";

		if (vWidth < 768) {
			imageKey = "medium_large";
		} else if (vWidth < 1024) {
			imageKey = "large";
		}

		return sizes[imageKey];
	};

	function lazify() {
		var vWidth = $(window).width(),
			imageKey = "hero-banner";

		// Get image side key
		if (vWidth < 768) {
			imageKey = "medium_large";
		} else if (vWidth < 1024) {
			imageKey = "large";
		}

		$(".lazy").each(function(index) {
			var imageSizes = $(this).data("image");

			// If it's a bg lazy image, set background-image, else set src
			if (imageSizes != null) {
				if ($(this).hasClass("bg")) {
					$(this).css("background-image", "url(" + imageSizes[imageKey] + ")");
				} else {
					$(this).prop("src", imageSizes[imageKey]);
					var spanFix = $(this)
						.wrap("<span></span>")
						.parent();
					$(this)
						.detach()
						.appendTo($(spanFix));
				}
				$(this)
					.removeAttr("data-image")
					.removeClass("lazy");
			}
		});
	}

	lazify();

	$(".hero .slides").slick({
		autoplay: true,
		autoplaySpeed: 7000,
		dots: true,
		arrows: false,
	});
	$(".hero .news-slides").slick({
		autoplay: true,
		autoplaySpeed: 7000,
		arrows: true,
		dots: true,
		responsive: [
			{
				breakpoint: 768,
				settings: "unslick"
			}
		]
	});

	// Nav events
	var nav_button = $(".mobile-header .menu-button"),
		nav = $(".mobile-header nav"),
		clone = $(".mobile-header .clone"),
		clone_nav = $(".mobile-header .clone nav"),
		clone_menu = $(".mobile-header .clone .menu"),
		clone_story = $(".mobile-header .clone .featured-story"),
		clone_made = false,
		hovering_nav = false;

	$(nav_button).on("click", function(e) {
		e.preventDefault();

		if ($(nav).hasClass("shown")) {
			$(nav).removeClass("shown");
			$("body").css("overflow", "auto");
			$(".mobile-header .menu-item-has-children").removeClass("open");
			$(nav).removeClass("sub-menu-open");
		} else {
			$(nav).addClass("shown");
			$("body").css("overflow", "hidden");
		}
	});

	$(".mobile-header .menu > li > a").on("mouseover", function(e) {
		e.preventDefault();

		if ($(window).width() > 1024) {
			// Desktop

			if ( $(this).parent().hasClass("menu-item-has-children")) {
				var clone_content = $(this).parent().clone();

				$(clone_menu).html(clone_content[0].outerHTML);
				clone_content = $(this).next().next().clone();
				$(clone_story).html(clone_content[0].outerHTML);
				$(clone).height($(clone_nav).outerHeight());
				clone_made = true;
			} else {
				$(clone_menu).html("");
				$(clone_story).html("");
				$(clone).height(0);
				clone_made = false;
			}
		}
	});

	$(".mobile-header").on("mouseleave", function(e) {
		if ($(window).width() > 1024) {
			// Desktop
			$(clone_menu).html("");
			$(clone_story).html("");
			$(clone).height(0);
			clone_made = false;
		}
	});

	$(".mobile-header .menu-item-has-children > a").on("click", function(e) {
		console.log($(window).width() < 1025);
		if ($(window).width() < 1025) {
			// Mobile Click
			e.preventDefault();
			console.log("nav parent item clicked");

			if (
				$(this)
					.parent()
					.hasClass("open")
			) {
				$(this)
					.parent()
					.removeClass("open");
				$(nav).removeClass("sub-menu-open");
			} else {
				$(this)
					.parent()
					.addClass("open");
				$(nav).addClass("sub-menu-open");
			}
		}
	});

	// Nav Desktop Hover
	// $(".mobile-header").on("hover", function(e) {
	//   if (e.type == "mouseleave") {
	//     hovering_nav = false;
	//     setTimeout(function() {
	//       if (!hovering_nav) {
	//         $(clone).height(0);
	//       }
	//     }, 50);
	//   } else {
	//     hovering_nav = true;
	//     if (clone_made) {
	//       $(clone).height($(clone_nav).outerHeight());
	//     }
	//   }
	// });

	$(".mobile-header .search-form svg").on("click", function(e) {
		if (
			$('.mobile-header .search-form input[type="search"]').hasClass("shown") ||
			$('.mobile-header .search-form input[type="search"]').val()
		) {
		} else {
			e.preventDefault();

			$('.mobile-header .search-form input[type="search"]').addClass("shown");
		}
	});

	// Tab module
	$(".tabs .tab-nav a").on("click", function(e) {
		e.preventDefault();

		$(".tabs .tab-nav > li a.active").removeClass("active");
		$(".tabs .tab-content > li")
			.height(0)
			.removeClass("active");
		$(this).addClass("active");

		var target = this.href.split("#").pop();
		$("#" + target)
			.addClass("active")
			.height("auto");
	});

	$(".accordion input.hidden").on("change", function(e) {
		if ($(this).prop("type") == "radio") {
			$(".accordion .wrapper").height(0);
		} else {
			if ($(this).prop("checked")) {
				$(this).next().next().height("auto");
			} else {
				$(this).next().next().height(0);
			}
		}
	});

	$("h1,h2,h3,h4,h5,h6,p").each(function() {
		if ($(this).find("iframe").length) {
			var iframe = $(this).find("iframe");
			var iframeSrc = $(iframe).attr('src');
			if ( iframeSrc.indexOf('youtube') != -1) {
				// do nothing
			} else {
				$(iframe).wrap('<div class="sixteenbynine"></div>');
			}
		} else if ($(this).hasClass("break") || $(this).find("img").length) {
			// do nothing
		} else if ($(this).parent().hasClass("format-content")) {
			// do nothing
		} else {
			if ($(this).find("a").length) {
				// $(this).find("a").text( $(this).find("a").text().replace(/\s([^\s<]+)\s*$/, "\u00A0$1") );
			} else if ($(this).find("strong").length) {
				// $(this).find("strong").text($(this).find("strong").text().replace(/\s([^\s<]+)\s*$/, "\u00A0$1"));
			} else {
				// $(this).text($(this).text().replace(/\s([^\s<]+)\s*$/, "\u00A0$1"));
			}
		}
	});

	var scrollWait = false,
		header = $(".mobile-header");
	$(window).scroll(function() {
		if (!scrollWait && $(window).width() > 1024) {
			scrollWait = true;
			setTimeout(function() {
				if ($(document).scrollTop() > 300) {
					$(header).addClass("compress");
				} else {
					$(header).removeClass("compress");
				}
				scrollWait = false;
			}, 150);
		}
	});
});
